import getCoordinateInputName from "src/decidim/geocoding/coordinate_input";
export default function attachGeocoding($input, options, callback) {
  const attachOptions = $.extend({}, options);
  const inputIdParts = $input.attr("id").split("_");
  inputIdParts.pop();
  const idPrefix = `${inputIdParts.join("_")}`;
  let latitudeName = "latitude";
  let longitudeName = "longitude";
  if ($input.length > 0) {
    latitudeName = getCoordinateInputName("latitude", $input, attachOptions);
    longitudeName = getCoordinateInputName("longitude", $input, attachOptions);
  }
  const config = $.extend({
    latitudeId: `${idPrefix}_latitude`,
    longitudeId: `${idPrefix}_longitude`,
    latitudeName,
    longitudeName
  }, options);
  let geocoded = false;
  const createCoordinateFields = () => {
    let $latitude = $(`#${config.latitudeId}`);
    if ($latitude.length < 1) {
      $latitude = $(`<input type="hidden" name="${config.latitudeName}" id="${config.latitudeId}" />`);
      $input.after($latitude);
    }
    let $longitude = $(`#${config.longitudeId}`);
    if ($longitude.length < 1) {
      $longitude = $(`<input type="hidden" name="${config.longitudeName}" id="${config.longitudeId}" />`);
      $input.after($longitude);
    }
  };
  const clearCoordinateFields = () => {
    if (geocoded) {
      return;
    }
    $(`#${config.latitudeId}`).val("").removeAttr("value");
    $(`#${config.longitudeId}`).val("").removeAttr("value");
  };
  const setCoordinates = (coordinates2) => {
    createCoordinateFields();
    $(`#${config.latitudeId}`).val(coordinates2[0]).attr("value", coordinates2[0]);
    $(`#${config.longitudeId}`).val(coordinates2[1]).attr("value", coordinates2[1]);
  };
  $input.on("change.decidim", () => {
    clearCoordinateFields();
  });
  $input.on("geocoder-suggest-coordinates.decidim", (_ev, coordinates2) => {
    setCoordinates(coordinates2);
    geocoded = true;
    if (typeof callback === "function") {
      callback(coordinates2);
      return;
    }
  });
  const coordinates = `${$input.data("coordinates")}`.split(",").map(parseFloat);
  if (Array.isArray(coordinates) && coordinates.length === 2) {
    setCoordinates(coordinates);
  }
}
